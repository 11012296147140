import React, { useEffect } from 'react'
const Special = () => {
  useEffect(() => {
    const classList = window.document.body.classList;
    while (classList.length > 0) {
      window.document.body.classList.remove(classList.item(0));
    }
    window.document.body.classList.add("rebeccapurple")
    return () => {
      window.document.body.classList.remove("rebeccapurple")
    };
  }, [])

  return (
    <>
    </>
  )
}

export default Special
