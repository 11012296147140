module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-embed-video","options":{"width":800,"ratio":1.77,"related":false,"noIframeBorder":true,"urlOverrides":[{"id":"youtube"}]}},{"resolve":"gatsby-remark-images","options":{"markdownCaptions":true,"disableBgImageOnAlpha":false}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1.0725rem"}},"gatsby-remark-copy-linked-files","gatsby-remark-smartypants",{"resolve":"gatsby-remark-autolink-headers","options":{"className":"header-anchor"}},{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"nofollow"}},{"resolve":"gatsby-remark-vscode","options":{"colorTheme":"Monokai"}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"My site","short_name":"ME","start_url":"/","background_color":"#131722","theme_color":"#131722","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7a2fa0ba0fc7466f888f8a38499aabba"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
